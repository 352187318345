import type { FeatureCollection } from 'geojson';
import jwt from 'jsonwebtoken';
import {
  BQ_TIMEOUT_MS,
  ERROR_413_IDENTIFIER,
  ERROR_413_MESSAGE,
  MAX_PAGE_PER_REQUEST,
  MAX_ROWS_PER_PAGE,
} from './../config/constants';
import { omitKeys } from './generics';
import {
  aggregateQuery,
  imputedQuery,
  populateForcedQueryWithFilters,
  type QueryProps,
} from './queryHelper';

// type QueryParameterType = {
//   type: string;
//   arrayType?: QueryParameterType;
//   structTypes?: {
//     name?: string;
//     type: QueryParameterType;
//     description?: string;
//   }[];
// };

// type QueryParameterValue = {
//   value?: string;
//   arrayValues?: QueryParameterValue[];
//   structValues?: Record<string, QueryParameterValue>;
// };

// type QueryParameter = {
//   name?: string;
//   parameterType: QueryParameterType;
//   parameterValue: QueryParameterValue;
// };

// type QueryRequest = {
//   kind?: string;
//   query: string;
//   maxResults?: number;
//   defaultDataset?: {
//     datasetId: string;
//     projectId: string;
//   };
//   timeoutMs?: number;
//   dryRun?: boolean;
//   useQueryCache?: boolean;
//   useLegacySql?: boolean;
//   parameterMode?: 'named' | 'positionnal';
//   queryParameters?: QueryParameter[];
//   location?: string;
//   formatOptions?: {
//     useInt64Timestamp?: boolean;
//   };
//   connectionProperties?: {
//     key: string;
//     value: string;
//   }[];
//   labels?: Record<string, string>;
//   maximumBytesBilled?: string;
//   requestId?: string;
//   createSession?: boolean;
// };

type TableFieldSchema = {
  name: string;
  type: string;
  mode: string;
  fields: TableFieldSchema[];
  description: string;
  policyTags: {
    names: string[];
  };
  maxLength: string;
  precision: string;
  scale: string;
  roundingMode:
    | 'ROUNDING_MODE_UNSPECIFIED'
    | 'ROUND_HALF_AWAY_FROM_ZERO'
    | 'ROUND_HALF_EVEN';
  collation: string;
  defaultValueExpression: string;
};

type QueryError = {
  reason: string;
  location: string;
  debugInfo: string;
  message: string;
};

export type QueryResults = {
  kind: string;
  schema: { fields: TableFieldSchema[] };
  jobReference: {
    projectId: string;
    jobId: string;
    location: string;
  };
  pageToken?: string;
  totalRows: string;
  rows?: {
    f: {
      v: string;
    }[];
  }[];
  errors?: QueryError[];
  totalBytesProcessed: string;
  jobComplete: boolean;
  cacheHit: boolean;
  sessionInfo?: {
    sessionId: string;
  };
  numDmlAffectedRows?: string;
  dmlStats?: {
    insertedRowCount: string;
    deletedRowCount: string;
    updatedRowCount: string;
  };
};

export type QueryInfos = Omit<QueryResults, 'rows' | 'schema'> & {
  duration: number;
};

export type ReturnedRow = Record<string, string | number | boolean>;

export type Query = {
  title: string | string[];
  sutitle?: string;
  str?: string; // Stringified query
  props: QueryProps;
  returnedRows?: ReturnedRow[];
  execution?: QueryInfos;
  columns?: Record<string, string>;
  type?: string;
  geojson?: FeatureCollection[] | undefined;
  suffixes?: Record<string, string>;
  imputeWith?: string | number; // Used for measure imputation when no data is available
};

type GoogleCredentials = {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};

export async function queryTable(query: string, cacheQueries = true) {
  // console.log({ query });
  const envCredentials = process.env.BIGQUERY_CREDENTIALS;
  if (!envCredentials)
    throw new Error('No BigQuery credentials found in environment variables');
  const credentials = JSON.parse(envCredentials) as GoogleCredentials;

  const now = Math.round(Date.now() / 1000);
  const payload = {
    iss: credentials.client_email,
    sub: credentials.client_email,
    aud: 'https://bigquery.googleapis.com/',
    iat: now,
    exp: now + 3600,
  };
  const token = jwt.sign(payload, credentials.private_key, {
    algorithm: 'RS256',
  });

  const authorizeHeaders = new Headers({
    Authorization: `Bearer ${token}`,
  });

  authorizeHeaders.append('Content-Type', 'application/json');

  const projectId = credentials.project_id;
  const datasetId = process.env.BIGQUERY_DATASET;
  const url = `https://bigquery.googleapis.com/bigquery/v2/projects/${projectId}/queries`;

  const dataset = {
    projectId,
    datasetId,
  };
  const bodyObject = {
    query,
    defaultDataset: dataset,
    location: 'EU',
    maxResults: MAX_ROWS_PER_PAGE,
    useQueryCache: cacheQueries,
    useLegacySql: false,
    timeoutMs: BQ_TIMEOUT_MS,
  };
  let body = JSON.stringify(bodyObject);
  const res = await fetch(url, {
    method: 'POST',
    headers: authorizeHeaders,
    body: body,
  });

  // check if the query has failed
  if (!res.ok) {
    const { error } = (await res.json()) as { error: QueryError };
    throw new Error(`Fetch has failed in 'queryTable': ${error.message}`);
  }

  const results = (await res.json()) as QueryResults;

  if (!results.jobComplete) {
    const jobCompleteErrorMessage =
      'Since the bigquery job has not completed, the request has been timed out. Please try again.';
    console.error({
      jobComplete: false,
      query,
      message: jobCompleteErrorMessage,
    });
    throw new Error(jobCompleteErrorMessage);
  }

  if (+results.totalRows > MAX_ROWS_PER_PAGE * MAX_PAGE_PER_REQUEST) {
    throw new Error(`${ERROR_413_IDENTIFIER}: ${ERROR_413_MESSAGE}`);
  }

  // check if we got an other pages to fetch and collect
  const nextPageToken = results.pageToken;
  if (nextPageToken) {
    const totalRows = parseInt(results.totalRows);
    let currentPage = 1;
    let remaningRows = totalRows - MAX_ROWS_PER_PAGE;

    while (
      nextPageToken &&
      currentPage < MAX_PAGE_PER_REQUEST &&
      remaningRows > 0
    ) {
      if (remaningRows < MAX_ROWS_PER_PAGE) {
        bodyObject.maxResults = remaningRows;
        body = JSON.stringify(bodyObject);
      }

      const nextRes = await fetch(`${url}?pageToken=${nextPageToken}`, {
        method: 'POST',
        headers: authorizeHeaders,
        body: body,
      });

      if (!nextRes.ok) {
        const { error } = (await nextRes.json()) as { error: QueryError };
        throw new Error(`Fetch has failed in 'queryTable': ${error.message}`);
      }

      const nextResults = (await nextRes.json()) as QueryResults;

      // We know that results.rows is defined because we have already fetched the first page
      results.rows = results.rows!.concat(nextResults.rows ?? []);
      results.pageToken = nextResults.pageToken;

      currentPage++;
      remaningRows = remaningRows - MAX_ROWS_PER_PAGE;
    }
  }

  return results as unknown as QueryResults;
}

export function extractRows(results: QueryResults) {
  const fields = results.schema.fields;
  const rows = results.rows;
  if (!rows) return [];
  // if (!rows) throw new Error('No results for query \n' + query);

  const namedRows = rows.map((row) => {
    const values = row.f;
    const newValue = {} as ReturnedRow;
    values.forEach((value, index) => {
      const field = fields[index]!;
      if (
        field.type === 'INTEGER' ||
        field.type === 'FLOAT' ||
        field.type === 'NUMERIC' ||
        field.type === 'BIGNUMERIC'
      ) {
        newValue[field.name] = value.v && +value.v;
      } else if (field.type === 'BOOLEAN' && value.v !== null) {
        newValue[field.name] = value.v.toLowerCase() === 'true';
      } else {
        newValue[field.name] = value.v;
      }
    });
    return newValue;
  });
  return namedRows;
}

export async function getRowsAndInfosFromQuery(
  query: string,
  cacheQueries = true
) {
  const startTime = Date.now();
  const results = await queryTable(query, cacheQueries);
  const endTime = Date.now();
  const rows = extractRows(results);
  const infos = new Map(Object.entries(results));
  infos.delete('rows');
  infos.delete('schema');
  infos.set('duration', (endTime - startTime).toString());
  return {
    rows,
    infos: Object.fromEntries(infos.entries()) as unknown as QueryInfos,
  };
}

export async function getRowsAndInfosFromQueryProps(
  queryProps: QueryProps,
  cacheQueries = true,
  returnWithQuery = false
) {
  let query = '';
  if ('stringified' in queryProps) {
    query = populateForcedQueryWithFilters(queryProps);
  } else if (queryProps.imputeWith !== undefined) {
    query = imputedQuery(queryProps, aggregateQuery);
  } else query = aggregateQuery(queryProps);

  const queryResults = await getRowsAndInfosFromQuery(query, cacheQueries);

  return Object.assign(
    { queryResults },
    returnWithQuery ? { strQuery: query } : {}
  ) as {
    queryResults: { rows: ReturnedRow[]; infos: QueryInfos };
    strQuery?: string;
  };
}

export async function getResultsFromQuery(
  query: Query,
  cacheQueries = true,
  returnWithQuery = false
) {
  const _query = JSON.parse(JSON.stringify(query)) as Query;

  const { queryResults, strQuery } = await getRowsAndInfosFromQueryProps(
    _query.props,
    cacheQueries,
    returnWithQuery
  );

  _query.returnedRows = queryResults.rows;
  _query.execution = queryResults.infos;
  if (strQuery) _query.str = strQuery;

  return _query;
}

export async function getResultsFromQueries(
  queries: Record<string, Query>,
  cacheQueries = true,
  returnWithQuery = false
) {
  const _queries = JSON.parse(JSON.stringify(queries)) as typeof queries;
  const queriesKeys = Object.keys(_queries);

  const queriesResults = await Promise.all(
    queriesKeys.map(async (key) =>
      getResultsFromQuery(_queries[key]!, cacheQueries, returnWithQuery)
    )
  );
  queriesResults.forEach((_, index) => {
    const key = queriesKeys[index]!;
    _queries[key] = queriesResults[index]!;
  });

  return _queries;
}

/**
 * DEPRECATED! This function is used for specific use cases and should not be used in general.
 * @param groupedResult
 * @returns
 */
export function transformKpiBuilderResult<T>(
  groupedResult: Record<string, T[]> | undefined,
  options: {
    thematicIdKey: keyof T;
    thematicLabelKey: keyof T;
    subthematicIdKey: keyof T;
    subthematicLabelKey: keyof T;
  }
) {
  if (!groupedResult) return [];
  return Object.keys(groupedResult).map((key) => ({
    thematic: {
      id: groupedResult[key]![0]![options.thematicIdKey]!,
      label: groupedResult[key]![0]![options.thematicLabelKey]!,
    },
    subthematics: groupedResult[key]!.map((subthematic) =>
      Object.assign(
        {
          id: subthematic[options.subthematicIdKey],
          label: subthematic[options.subthematicLabelKey],
        },
        omitKeys(subthematic, Object.values(options))
      )
    ),
  }));
}
