import type { ActiveFilters, FilterSelect, IdLabelObject } from '@/types';
import type { Query, ReturnedRow } from 'database/bigQueryHelper';
import { getResultsFromQueries } from 'database/bigQueryHelper';
import { objectsHaveSamePropertiesAndValues } from 'database/generics';
import { get } from 'lodash';

export async function loadFilters(filters: FilterSelect[]) {
  try {
    if (filters.length === 0) return filters;

    const requests = filters.map(
      (filter: FilterSelect) =>
        `SELECT ${filter.fieldid} as id, ${filter.fieldlabel} as label FROM \`${process.env.BIGQUERY_PROJECT_ID}.${process.env.BIGQUERY_DATASET}.${filter.table}\` WHERE num_branche NOT IN (9999) GROUP BY 1, 2 ORDER BY 2 ASC`
    );

    const responses = await Promise.all(
      requests.map((request) =>
        getResultsFromQueries({
          filterreq: {
            title: 'Filter',
            props: {
              stringified: request,
            },
          } as Query,
        })
      )
    );

    filters.forEach((filter, idx) => {
      filter.values = get(
        responses,
        `${idx}.filterreq.returnedRows`,
        [] // as default value
      ) as unknown as IdLabelObject[];
    });

    return filters;
  } catch (err) {
    console.log({ err, location: 'loadPerimetresData' });
    return filters;
  }
}

export async function executeOnBigQuery(_q: Record<string, Query>) {
  const _r = await getResultsFromQueries(_q);

  return _r;
}

export async function getKpiData(
  fselection: ActiveFilters,
  // eslint-disable-next-line @typescript-eslint/ban-types
  getQueriesWithFilters: Function,
  exec: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    before?: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    after?: Function;
  }
): Promise<void> {
  // setFiltersList(selectedFilters);
  if (exec.before) exec.before(fselection);

  const _selected = Object.keys(fselection).reduce<
    Record<string, (number | string)[]>
  >((acc, curr) => {
    if (Array.isArray(fselection[curr]) && !(fselection[curr]?.length === 0)) {
      acc[curr] = fselection[curr]!.map((f) =>
        curr.startsWith('num_') ? parseInt(f.id) : f.id
      );
    }
    return acc;
  }, {});

  // const queries = _getPagesQueries(_selected);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const queries = getQueriesWithFilters(_selected);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const _d = await fetch('/api/bigquery', {
    method: 'POST',
    body: JSON.stringify(queries),
  }).then((res) => res.json());

  // setKpiData(_d);
  if (exec.after) exec.after(_d);
}

export function nullImputation(
  _a: Record<string, Query>,
  fieldid: string,
  replacement: string | number
) {
  const _replaceValuesIfNullWithInArray = (
    arr: ReturnedRow[] | undefined,
    fieldid: string,
    replacement: string | number
  ) => {
    return !arr
      ? []
      : arr.map((item) => {
          if (fieldid in item && item[fieldid] === undefined) {
            item[fieldid] = replacement;
          }
          return item;
        });
  };

  for (const [key, val] of Object.entries(_a)) {
    _a[key] = {
      ...val,
      returnedRows: _replaceValuesIfNullWithInArray(
        val.returnedRows,
        fieldid,
        replacement
      ),
    };
  }

  return _a;
}

export function withCnieg(
  kpiData: Query | undefined,
  cniegKpiData: Query | undefined,
  completeWithCniegWhenNotFound = false
) {
  if (!kpiData)
    return {
      title: '',
      props: {
        stringified: '',
      },
      returnedRows: [],
    } as Query;
  if (!cniegKpiData) return kpiData;

  let rows = kpiData.returnedRows;
  const cniegRows = cniegKpiData.returnedRows;

  rows = rows?.map((r) => {
    const cniegCorrespondingRow = cniegRows?.find((cr) => {
      return objectsHaveSamePropertiesAndValues(r, cr, ['nb', 'absolute']);
    });

    if (cniegCorrespondingRow) {
      return {
        ...r,
        ...cniegCorrespondingRow,
      };
    } else {
      return r;
    }
  });

  // populate with cnieg data when not found
  let missingRows;
  if (completeWithCniegWhenNotFound) {
    missingRows = cniegRows?.filter((cr) => {
      const correspondingRow = rows?.find((r) => {
        return objectsHaveSamePropertiesAndValues(r, cr, ['nb', 'absolute']);
      });

      return !correspondingRow;
    });
  }

  rows = rows?.concat(missingRows ?? []);

  return {
    ...kpiData,
    returnedRows: rows,
  };
}

export function roundToTheNearestTen(kpiData: Query) {
  const rows = (kpiData.returnedRows ?? []).map(
    (r) =>
      ({
        ...r,
        nb: r.nb && Math.round((r.nb as number) / 10) * 10,
      }) as ReturnedRow
  );

  return {
    ...kpiData,
    returnedRows: rows,
  };
}
