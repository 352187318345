// Description: Generic functions

/**
 * Omit a key from an object
 * @param key
 * @param obj
 * @returns
 */
export function omit<T>(obj: T | Omit<T, keyof T>, key: keyof T): Omit<T, keyof T> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

/**
 * Omit keys from an object
 * @param key
 * @param obj
 * @returns
 */
export function omitKeys<T>(obj: T, keys: (keyof T)[]) {
  let _obj: T | Omit<T, keyof T> = obj;
  keys.forEach((key) => {
    _obj = omit(_obj, key);
  });
  return _obj;
}

/**
 * Group an array of objects by a key or a group of keys
 * @param array
 * @param options
 */
export function groupBy<T>(array: T[], options?: { key?: keyof T; keys?: (keyof T)[] }) {
  const { key, keys } = options ?? {};
  if (key) {
    // group by one key
    return array.reduce(
      (rv: Record<string, T[]>, x: T) => {
        (rv[x[key] as string] = rv[x[key] as string] || []).push(x);
        return rv;
      },
      {} as Record<keyof T, T[]>
    );
  } else if (keys) {
    // group by multiple keys
    return array.reduce<Record<string, T[]>>((rv: Record<string, T[]>, x: T) => {
      const groupKey = keys.map((key) => x[key]).join('-');
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (rv[groupKey] = rv[groupKey] ?? []).push(x);
      return rv;
    }, {});
  } else {
    throw new Error('No key or keys provided');
  }
}

/**
 * Get the max value of an array of objects by a key
 * @param array
 * @param key
 * @returns
 */
export function max<T>(array: T[]) {
  return array.reduce((a, b) => (a > b ? a : b));
}

/**
 * Check if the first object have the same properties and values as the second object
 * @param a
 * @param b
 * @param excludedKeys
 * @returns
 */
export function objectsHaveSamePropertiesAndValues<T>(
  a: T,
  b: T,
  excludedKeys?: string[]
): boolean {
  for (const key in a) {
    if (excludedKeys?.includes(key)) {
      continue;
    } else {
      if (!!a[key] && a[key] !== b[key]) {
        return false;
      }
    }
  }
  return true;
}
