import type { ReturnedRow } from 'database/bigQueryHelper';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import type { FeatureCollection } from 'geojson';
import { assign, groupBy, sumBy } from 'lodash';
import { numberToString } from './numberToString';
dayjs.locale('fr-fr');

export function percentageFromArray(
  value: number,
  data: ReturnedRow[],
  field: string
) {
  return `${Math.round(
    (value /
      data.reduce((acc, curr) => {
        const value = curr[field];
        if (typeof value === 'number') {
          return acc + value;
        }
        return acc;
      }, 0)) *
      100
  )}%`;
}

export function tauxEvolution(rows: ReturnedRow[] | undefined, field: string) {
  if (rows === undefined || rows.length === 0) return 0;

  const annee = Math.max(
    ...rows.map((row) => {
      if (typeof row.annee !== 'number') {
        return -1;
      }
      return row.annee;
    })
  );

  if (annee !== -1) {
    // We are sure that there are an element with annee === annee
    const rowAnnee = rows.find((row) => row.annee === annee)!;
    const rowAnneePrec = rows.find((row) => row.annee === annee - 1);
    if (rowAnneePrec !== undefined) {
      const valueAnnee = rowAnnee[field];
      const valueAnneePrec = rowAnneePrec[field];
      if (
        typeof valueAnnee === 'number' &&
        typeof valueAnneePrec === 'number'
      ) {
        return (
          Math.round(((valueAnnee - valueAnneePrec) / valueAnneePrec) * 100) /
          100
        );
      }
    }
  }
  // We look for the month with the biggest value
  const mois = rows.map((row) => {
    if (typeof row.code_mois === 'number') {
      return row.code_mois;
    }
    if (typeof row.code_mois === 'string') {
      return parseInt(row.code_mois);
    }
    return -1;
  });
  const moisMax = Math.max(...mois);
  const moisMin = Math.min(...mois);
  if (moisMax !== -1 && moisMin !== -1) {
    // We are sure that there are elements with moisMax and MoisMin
    const rowMoisMax = rows.find((row) => row.code_mois === moisMax)!;
    const rowMoisMin = rows.find((row) => row.code_mois === moisMin)!;
    const valueMoisMax = rowMoisMax[field];
    const valueMoisMin = rowMoisMin[field];
    if (typeof valueMoisMax === 'number' && typeof valueMoisMin === 'number') {
      return (
        Math.round(((valueMoisMax - valueMoisMin) / valueMoisMin) * 100) / 100
      );
    }
  }
  return 0;
}

// Old version of tauxEvolution
// export function tauxEvolution(data: ReturnedRow[] | undefined, field: string) {
//   // calculer la valeur la plus grande dans data
//   if (data && !isEmpty(data)) {
//     const annee = Math.max(...data.map((d) => d.annee as number));
//     // calculer l'annee précédente dans data (annee - 1)
//     const anneePrecedente = annee - 1;
//     // calculer le taux d'évolution entre les deux années
//     const anneeCourante: ReturnedRow = data.find((d) => d.annee === annee) || { [field]: 0 };
//     const anneePrecedenteData: ReturnedRow = data.find((d) => d.annee === anneePrecedente) || {
//       [field]: 0,
//     };
//     if (anneeCourante && anneePrecedenteData && anneePrecedenteData[field] !== 0) {
//       const tauxEvolution =
//         ((anneeCourante[field] as number) - (anneePrecedenteData[field] as number)) /
//         (anneePrecedenteData[field] as number);
//       return Math.round(tauxEvolution * 100) / 100;
//     } else {
//       const valueMaxMonth: number = Math.max(...data.map((d) => parseInt(d.code_mois as string)));
//       const valueMinMonth: number = Math.min(...data.map((d) => parseInt(d.code_mois as string)));
//       const dataMaxMonth: ReturnedRow = data.find(
//         (d) => d.code_mois === valueMaxMonth.toString()
//       ) || { [field]: 0 };
//       const dataMinMonth: ReturnedRow = data.find(
//         (d) => d.code_mois === valueMinMonth.toString()
//       ) || { [field]: 0 };
//       if (dataMaxMonth && dataMinMonth && dataMinMonth[field] !== 0) {
//         const tauxEvolution =
//           ((dataMaxMonth[field] as number) - (dataMinMonth[field] as number)) /
//           (dataMinMonth[field] as number);
//         return Math.round(tauxEvolution * 100) / 100;
//       }
//       return 0;
//     }
//   }
//   return 0;
// }

export function formatNumber(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}

// trancheAge is a string like "de 0 à 14 ans"
function medianAge(trancheAge: string): number {
  const ageRangeValues = trancheAge.split(' ');
  if (ageRangeValues.length > 0 && typeof ageRangeValues[1] === 'string') {
    const ageRangeLimits = ageRangeValues[1].split('-');
    if (
      ageRangeLimits.length === 2 &&
      typeof ageRangeLimits[0] === 'string' &&
      typeof ageRangeLimits[1] === 'string'
    ) {
      const lowerLimit = parseInt(ageRangeLimits[0]);
      const upperLimit = parseInt(ageRangeLimits[1]);
      return (lowerLimit + upperLimit) / 2;
    }
  }
  throw new Error(`Invalid trancheAge: ${trancheAge}`);
}

function findMedianAge(row: ReturnedRow): number {
  if (!('tranche_age' in row))
    throw new Error('Invalid row (missing tranche_age field)');
  if (typeof row.tranche_age !== 'string')
    throw new Error('Invalid row (field tranche_age is not string)');
  if (row.num_tranche_age === 1) {
    return 11;
  }
  if (row.num_tranche_age === 7) {
    return 80;
  }
  return medianAge(row.tranche_age);
}

export function calculateAge(rows: ReturnedRow[]) {
  let totalAge = 0;
  let total = 0;

  for (const row of rows) {
    const medianAge = findMedianAge(row);
    if (!('nb' in row)) throw new Error('Invalid row (missing nb field)');
    if (typeof row.nb !== 'number')
      throw new Error('Invalid row (field nb is not number)');
    totalAge += row.nb * medianAge;
    total += row.nb;
  }
  const averageAge = totalAge / total;
  return Math.round(averageAge);
}

// Old version of calculateAge
// export function calculateAge(data: ReturnedRow[]) {
//   let totalAge = 0;
//   let total = 0;
//   for (let i = 0; i < data.length; i++) {
//     const ageRange: any = data[i];
//     const ageRangeCount: any = ageRange.nb;

//     // calculate the midpoint of the age range
//     let ageMidpoint = 0;
//     const ageRangeValues = ageRange?.tranche_age?.split(' ');
//     if (ageRange.num_tranche_age === 1) {
//       ageMidpoint = 11;
//     } else if (ageRange.num_tranche_age === 7) {
//       ageMidpoint = 80;
//     } else {
//       const ageRangeLimits = ageRangeValues[1].split('-');
//       const lowerLimit = parseInt(ageRangeLimits[0]);
//       const upperLimit = parseInt(ageRangeLimits[1]);
//       ageMidpoint = (lowerLimit + upperLimit) / 2;
//     }

//     // calculate the total age based on the age range count and midpoint
//     totalAge += ageRangeCount * ageMidpoint;
//     total += ageRangeCount;
//   }
//   const averageAge = totalAge / total;
//   return Math.round(averageAge);
// }

export function addDataTable(data: ReturnedRow[], field: string) {
  const newData = [...data];
  for (const row of newData) {
    const value = row[field];
    if (typeof value === 'number') {
      assign(row, {
        somme: sumBy(newData, field),
        pourcentage: percentageFromArray(value, newData, field),
      });
    }
    if ('mois' in row && typeof row.mois === 'number') {
      assign(row, {
        mois_label: dayjs()
          .month(row.mois - 1)
          .format('MMMM'),
      });
    }
  }
  return newData;
}

export function replaceOtherInResult(
  data: ReturnedRow[],
  top: number,
  field: string
) {
  if (data.length <= top) return data;
  const totalSum = Math.round(
    data.reduce(
      // sum of all values
      (acc: number, curr: ReturnedRow) => acc + (curr.nb as number),
      0
    )
  );
  let result = data // array of top values
    .slice(0, top)
    .map((d: ReturnedRow) => ({ ...d, nb: Math.round(d.nb as number) }));
  const other = data // sum of values out of top
    .slice(top)
    .reduce(
      (acc: number, curr: ReturnedRow) => acc + Math.round(curr.nb as number),
      0
    );
  const sum = result.reduce(
    // sum of top values
    (acc: number, curr: ReturnedRow) => acc + Math.round(curr.nb as number),
    0
  );
  const total = sum + other; // sum of top values + sum of values out of top
  result = [
    ...result,
    {
      [`${field}`]: 'Autres',
      nb: total !== totalSum ? other + (totalSum - total) : other,
    }, // add the difference between total and totalSum to the last value in case of rounding error
  ];
  return result;
}

export function addDifferenceRoundedValueFromTotal(data: ReturnedRow[]) {
  const totalSum = Math.round(
    // sum of all values and round it
    data.reduce(
      (acc: number, curr: ReturnedRow) => acc + (curr.nb as number),
      0
    )
  );
  const total = data.reduce(
    // sum of all rounded values
    (acc: number, curr: ReturnedRow) => acc + Math.round(curr.nb as number),
    0
  );
  const difference = totalSum - total;
  return data.map((d: ReturnedRow, index: number) => ({
    ...d,
    nb:
      index === data.length - 1
        ? Math.round(d.nb as number) + difference
        : Math.round(d.nb as number),
  }));
}

export function getEvolutionRate(currentData: number, previousData: number) {
  if (previousData === 0) return 0;
  return ((currentData - previousData) / Math.abs(previousData)) * 100;
}

export function addEvolutionRateInResult(
  data: ReturnedRow[] | undefined,
  field: string,
  limit?: number,
  periodField?: string,
  selectedYear?: number
) {
  if (!data) return [];
  if (periodField)
    return getPrevYearDataForIncompleteYear(data, periodField, field, limit);
  // get biggest year
  const year =
    selectedYear ??
    Math.max(...data.map((d: ReturnedRow) => d.annee as number));
  // get smallest year
  const prevYear = selectedYear
    ? selectedYear - 1
    : Math.min(...data.map((d: ReturnedRow) => d.annee as number));
  // get data for biggest year
  const yearData = data
    .filter((d: ReturnedRow) => d.annee === year)
    .slice(0, limit);
  // get data for smallest year
  const prevYearData = data.filter((d: ReturnedRow) => d.annee === prevYear);
  // set new data with evolution rate for biggest year with data for smallest year
  const result = yearData.map((d: ReturnedRow) => ({
    ...d,
    evolution_rate:
      getEvolutionRate(
        d.nb as number,
        prevYearData.find((prevD: ReturnedRow) => prevD[field] === d[field])
          ?.nb as number
      ) || 0,
  }));
  return result;
}

export function addEvolutionFactorInResult(
  data: ReturnedRow[] | undefined,
  field: string,
  limit?: number,
  selectedYear?: number
) {
  if (!data) return [];
  // get biggest year
  const year =
    selectedYear ??
    Math.max(...data.map((d: ReturnedRow) => d.annee as number));
  // get smallest year
  const prevYear = selectedYear
    ? selectedYear - 1
    : Math.min(...data.map((d: ReturnedRow) => d.annee as number));
  // get data for biggest year
  const yearData = data
    .filter((d: ReturnedRow) => d.annee === year)
    .slice(0, limit);
  // get data for smallest year
  const prevYearData = data.filter((d: ReturnedRow) => d.annee === prevYear);
  // set new data with evolution rate for biggest year with data for smallest year
  const result = yearData.map((d: ReturnedRow) => ({
    ...d,
    evolution_factor: prevYearData.find(
      (prevD: ReturnedRow) => prevD[field] === d[field]
    )
      ? '×' +
        numberToString(
          (d.nb as number) /
            (prevYearData.find(
              (prevD: ReturnedRow) => prevD[field] === d[field]
            )?.nb as number),
          true,
          2
        )
      : '-',
  }));
  return result;
}

export function getShareForLineChart(data: ReturnedRow[] | undefined) {
  if (!data) return [];
  const years = data
    .map((d: ReturnedRow) => d.annee as number)
    .filter((value, index, array) => array.indexOf(value) === index);

  const result = years.map((year: number) => {
    const yearData = data.filter((d: ReturnedRow) => d.annee === year);
    const total = sumBy(yearData, 'nb');
    return yearData.map((d: ReturnedRow) => ({
      ...d,
      nb: Math.round(((d.nb as number) / total) * 100),
      absolute: d.nb,
    }));
  }) as ReturnedRow[][];
  return result.flat() satisfies ReturnedRow[];
}

export function replaceVoidValueByZero(
  data: ReturnedRow[] | undefined,
  fieldToGroup: string,
  fieldToCheck: string
) {
  if (!data) return [];
  const groupedData = groupBy(data, fieldToGroup);
  const aggLabel = data
    .map((d: ReturnedRow) => d[fieldToCheck])
    .filter(
      (value, index, array) => array.indexOf(value) === index
    ) as string[];
  // check if in groupedData there is a value for each aggLabel
  const result = Object.keys(groupedData).map((key: string) => {
    const dataForGroup = groupedData[key]!;
    const dataForGroupLabel = dataForGroup.map(
      (d: ReturnedRow) => d[fieldToCheck]
    );
    const missingLabel = aggLabel.filter(
      (label: string) => !dataForGroupLabel.includes(label)
    );
    const missingData = missingLabel.map((label: string) => ({
      [`${fieldToGroup}`]: key,
      [`${fieldToCheck}`]: label,
      nb: 0,
    }));
    return [...dataForGroup, ...missingData];
  });
  // console.log(result.flat());
  return result.flat();
}

export function multiplyResultByNumber(
  data: ReturnedRow[] | undefined,
  number: number
) {
  if (!data) return [];
  return data.map((d: ReturnedRow) => ({
    ...d,
    nb: Math.round((d.nb as number) * number),
  }));
}

export function createFeatureCollectionFromResult(
  data: ReturnedRow[] | undefined,
  geoPoint: ReturnedRow[] | undefined
) {
  if (!data || !geoPoint) return [];
  const geoFeature = {
    type: 'FeatureCollection',
    features: [
      ...geoPoint
        .filter(
          (d: ReturnedRow) =>
            data.find((dataD: ReturnedRow) => dataD.code === d.code)?.nb
        )
        .map((d: ReturnedRow) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [d.x, d.y],
          },
          properties: {
            label: d.nom as string,
            code: d.code as string,
            group:
              (d.num_reg as number) ||
              (d.code_reg as number) ||
              (d.code_dep as string) ||
              (d.code as string),
            nb:
              (data.find((dataD: ReturnedRow) => dataD.code === d.code)
                ?.nb as number) || 0,
            x: d.x,
            y: d.y,
          },
        })),
    ],
  } as FeatureCollection;
  return geoFeature;
}

export function getDifferentielByYear(
  data: ReturnedRow[] | undefined,
  periodField: string
) {
  if (!data) return [];
  const years = data
    .map((d: ReturnedRow) => d[periodField] as number)
    .filter((value, index, array) => array.indexOf(value) === index);
  const result: ReturnedRow[] = [];
  data.forEach((d: ReturnedRow) => {
    const year = Math.max(...years);
    if (d[periodField] === year) {
      const prevYearData = data.find(
        (prevD: ReturnedRow) =>
          prevD[periodField] === year - 1 && prevD.code === d.code
      );
      const diff = prevYearData
        ? (d.nb as number) - (prevYearData.nb as number)
        : 0;
      result.push({
        ...d,
        differentiel: diff,
        taux:
          d.nb !== 0 ? Math.round((diff / (d.nb as number)) * 10000) / 100 : 0,
      });
    }
  });
  return result;
}

export function reduceDepToRegData(
  data: ReturnedRow[] | undefined,
  differentiel = false
) {
  const regions = data
    ?.map((d: ReturnedRow) => d.num_region as number)
    .filter((value, index, array) => array.indexOf(value) === index);
  const regDatas = regions?.map((reg: number) => {
    const regData = data?.filter((d: ReturnedRow) => d.num_region === reg);
    const regSum = sumBy(regData, 'nb');
    if (differentiel) {
      const totalDiff = sumBy(regData, 'differentiel');
      return {
        code: reg,
        region: regData?.[0]?.region ?? 'Région',
        nb: (totalDiff / regSum) * 100,
      };
    }
    return {
      code: reg,
      region: regData?.[0]?.region ?? 'Région',
      nb: regSum,
    };
  });
  return regDatas as ReturnedRow[];
}

export function getDifferentielRate(data: ReturnedRow[] | undefined) {
  if (!data) return [];
  const result = data.map((d: ReturnedRow) => ({
    ...d,
    nb: d.nb !== 0 ? ((d.differentiel as number) / (d.nb as number)) * 100 : 0,
  }));
  return result;
}

export function normalizeValue(
  value: number,
  min: number,
  max: number
): number {
  return Math.sqrt((value - min) / (max - min));
}

function handleNegativeValueForRange(
  data: ReturnedRow[] | undefined,
  step = 5
) {
  if (!data) return [];
  const allValues = data
    .map((d: ReturnedRow) => d.nb as number)
    .sort((a, b) => a - b);
  const min = allValues[0] ?? 0;
  const max = allValues[allValues.length - 1] ?? 0;
  const nbStep = max / step;
  let result: number[] = [];
  for (let i = 0; i < step; i++) {
    result.push(min + i * nbStep);
  }
  // sort result by asc
  result = result.sort((a, b) => a - b);
  const size = result.map((d: number) => {
    // normalize value between min and max for negative values
    return normalizeValue(d, min, max);
  });
  const range = result // create range for positive values
    .map((d: number, index: number) => {
      return [d, size[index]! * 44];
    })
    .flat();
  return range;
}

export function generateRangeFromResult(
  data: ReturnedRow[] | undefined,
  step = 5,
  handleNegative = false
) {
  if (!data) return [];
  if (handleNegative) return handleNegativeValueForRange(data, step);
  let allValues = data
    .map((d: ReturnedRow) => d.nb as number)
    .sort((a, b) => a - b);
  if (allValues.some((d: number) => d < 0)) {
    const min = Math.min(...allValues);
    if (Math.abs(min) > Math.max(...allValues)) {
      allValues.push(Math.abs(min));
    }
    allValues = allValues.filter((d: number) => d > 0);
  }
  const min = allValues[0] ?? 0;
  const max = allValues[allValues.length - 1] ?? 0;
  const nbStep = max / step;
  const result: number[] = [];
  for (let i = 0; i < step; i++) {
    result.push(min + i * nbStep);
  }
  const size = result.map((d: number) => {
    // normalize value between 0 and 1 for positive values
    return normalizeValue(d, 0, max);
  });
  const positiveRange = result // create range for positive values
    .map((d: number, index: number) => {
      return [d, size[index]! * 44];
    })
    .flat();
  const negativeRange = result // create range for negative values
    .reverse()
    .filter((d: number) => d !== 0)
    .map((d: number, index: number) => {
      return [-1 * d, size[size.length - index - 1]! * 44];
    })
    .flat();
  negativeRange[0] =
    -1 * max < negativeRange[0]! ? (-1 * max)! : negativeRange[0]!; // set first value to -max
  return [...negativeRange, ...positiveRange]; // return range for negative values and positive values
}

export function getPrevYearDataForIncompleteYear(
  data: ReturnedRow[] | undefined,
  periodField: string, // month field to check
  field?: string, // field to check to group by
  limit?: number
) {
  if (!data) return [];
  const lastYear = Math.max(
    ...data
      .map((d: ReturnedRow) => d.annee as number)
      .filter((value, index, array) => array.indexOf(value) === index)
  );
  const lastYearData = data.filter((d: ReturnedRow) => d.annee === lastYear);
  const prevYearData = data.filter(
    (d: ReturnedRow) =>
      d.annee === lastYear - 1 &&
      // filter month that are not in lastYearData
      lastYearData
        .map((d: ReturnedRow) => d[periodField])
        .includes(d[periodField])
  );
  if (field) {
    // group by field to sum nb
    const lastYearDataGrouped = groupBy(lastYearData, field);
    const prevYearDataGrouped = groupBy(prevYearData, field);

    const result = Object.keys(lastYearDataGrouped).map((key: string) => {
      const lastYearDataForGroup = lastYearDataGrouped[key]!;
      const prevYearDataForGroup = prevYearDataGrouped[key]!;
      return {
        ...lastYearDataForGroup[0],
        nb: sumBy(lastYearDataForGroup, 'nb'),
        prevNb: sumBy(prevYearDataForGroup, 'nb'),
        evolution_rate: getEvolutionRate(
          sumBy(lastYearDataForGroup, 'nb'),
          sumBy(prevYearDataForGroup, 'nb')
        ),
      };
    });
    if (limit) return result.slice(0, limit).sort((a, b) => b.nb - a.nb);
    return result;
  }
  const result = lastYearData.map((d: ReturnedRow) => ({
    ...d,
    nb: d.nb as number,
    prevNb: prevYearData.find(
      (prevD: ReturnedRow) => prevD[periodField] === d[periodField]
    )?.nb,
    evolution_rate: getEvolutionRate(
      d.nb as number,
      prevYearData.find(
        (prevD: ReturnedRow) => prevD[periodField] === d[periodField]
      )?.nb as number
    ),
  }));
  if (limit) return result.slice(0, limit);
  return result;
}

export function formatMonthToYear(
  data: ReturnedRow[] | undefined,
  periodField: string
) {
  if (!data) return [];
  const years = data
    .map((d: ReturnedRow) => d[periodField] as number)
    .filter((value, index, array) => array.indexOf(value) === index);
  const result = years.map((year: number) => {
    const yearData = data.filter((d: ReturnedRow) => d[periodField] === year);
    const prevYearData = data
      .filter((d: ReturnedRow) => d[periodField] === year - 1)
      .slice(0, yearData.length);
    return {
      agg1_label: 'Total',
      period: year,
      nb: sumBy(yearData, 'nb'),
      evolution_rate:
        prevYearData.length > 0
          ? getEvolutionRate(sumBy(yearData, 'nb'), sumBy(prevYearData, 'nb'))
          : null,
    };
  });
  return result as ReturnedRow[];
}

export function formatMonthToQuarter(
  data: ReturnedRow[] | undefined,
  periodField: string,
  year: number,
  measureField?: string
) {
  const measurefield = measureField ?? 'nb';
  if (!data) return [];
  const yearData = data.filter((d: ReturnedRow) => d[periodField] === year);
  const prevYearData = data.filter(
    (d: ReturnedRow) => d[periodField] === year - 1
  );
  const result = Array.from({ length: 4 }, (_, i) => i + 1).map(
    (quarter: number, index: number) => {
      const period = yearData.slice(index * 3, index * 3 + 3);
      if (prevYearData.length > 0) {
        const prevPeriod = prevYearData.slice(index * 3, index * 3 + 3);
        return [
          {
            period: `T${quarter}`,
            agg1_label: year,
            nb: period.length === 3 ? sumBy(period, measurefield) : null,
            evolution_rate:
              period.length === 3
                ? getEvolutionRate(
                    sumBy(period, measurefield),
                    sumBy(prevPeriod, measurefield)
                  )
                : null,
          },
          {
            period: `T${quarter}`,
            agg1_label: year - 1,
            nb: sumBy(prevPeriod, measurefield),
          },
        ].flat();
      }
      return {
        period: `T${quarter}`,
        agg1_label: year,
        nb: period.length === 3 ? sumBy(period, measurefield) : null,
      };
    }
  );
  return result.flat() as ReturnedRow[];
}

export function getSelectedYearMonth(
  data: ReturnedRow[] | undefined,
  periodField: string,
  year: number,
  measureField?: string
) {
  const measurefield = measureField ?? 'nb';
  if (!data) return [];
  const yearData = data.filter((d: ReturnedRow) => d[periodField] === year);
  const prevYearData = data.filter(
    (d: ReturnedRow) => d[periodField] === year - 1
  );
  if (prevYearData.length > 0) {
    const prevYearResult = prevYearData.map((d: ReturnedRow, index: number) => [
      {
        period: dayjs().month(index).format('MMMM'),
        agg1_label: year,
        nb: (yearData[index]?.[measurefield] as number) || null,
        evolution_rate: yearData[index]?.[measurefield]
          ? getEvolutionRate(
              yearData[index]![measurefield] as number,
              d[measurefield] as number
            )
          : null,
      },
      {
        period: dayjs().month(index).format('MMMM'),
        agg1_label: year - 1,
        nb: d[measurefield] as number,
      },
    ]);
    return [...prevYearResult.flat()] as ReturnedRow[];
  }
  const result = yearData.map((d: ReturnedRow, index: number) => ({
    period: dayjs().month(index).format('MMMM'),
    agg1_label: year,
    nb: d[measurefield] as number,
  }));
  return result as ReturnedRow[];
}

export function addNewColumnFromData(
  source: ReturnedRow[] | undefined,
  target: ReturnedRow[] | undefined,
  aggregatedField: string,
  field: string
) {
  if (!source || !target) return [];
  const result = target.map((d: ReturnedRow) => ({
    ...d,
    [`${field}`]: source.find(
      (sourceD: ReturnedRow) => sourceD[aggregatedField] === d[aggregatedField]
    )?.nb,
  })) as ReturnedRow[];
  return result;
}
