import { Fragment, useEffect, useState } from 'react';

import { Combobox, Transition } from '@headlessui/react';

// types
import { useZIndex } from '@/contexts/ZIndex';
import type { IdLabelObject } from '@/types';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import sortBy from 'lodash/sortBy';

type MultiSelectProps = {
  options?: IdLabelObject[] | null;
  title?: string;
  inputValue?: string;
  placeholder?: string;
  inputColor?: string;
  selected?: IdLabelObject[];
  extraOption?: IdLabelObject;
  defaultValues?: IdLabelObject[];
  disabled?: boolean;
  widthTailwind?: string;
  setValues?: (values: IdLabelObject[]) => void;
  asyncSetValues?: (values: IdLabelObject[]) => Promise<void>;
};
/**
 * Multiselect component with HeadlessUI Combobox
 * set values with setValues or asyncSetValues props after leave transition
 * @param param0
 * @returns
 */
export default function MultiSelect({
  options,
  title,
  inputValue,
  placeholder,
  inputColor,
  selected,
  extraOption,
  disabled,
  widthTailwind,
  setValues,
  asyncSetValues,
}: MultiSelectProps) {
  const [localSelection, setLocalSelection] = useState<IdLabelObject[]>(
    selected ?? []
  );
  // const [localInputValue, setLocalInputValue] = useState<string | undefined>(inputValue);
  const [search, setSearch] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false); // for focus status of input
  const [selectAllStatus, setSelectAllStatus] = useState<boolean>(false); // for select all status

  // Extra treatment for global z-index: this aim to put the focus on the page when the modal opens and remove it when it closes
  const globalZIndex = useZIndex();
  const updateGlobalZIndex = {
    onOpen: () => {
      globalZIndex.setZIndex('select', 'opacity-0 -z-10');
    },
    onClose: () => {
      globalZIndex.setZIndex('select', 'opacity-100 z-0');
    },
  };

  const handleChange = (newSelection: IdLabelObject[]) => {
    const selectAllClicked = newSelection.find(
      (item) => item.id === 'selectall'
    );
    if (selectAllClicked) {
      if (selectAllStatus) {
        // unselect all
        newSelection = [];
        setSelectAllStatus(false);
      } else {
        // select all
        newSelection = options ?? [];
        setSelectAllStatus(true);
      }
    }
    setLocalSelection(newSelection);
  };

  /**
   * Handle after select menu leave transition
   *
   * @local
   * @returns
   * @async
   */
  const handleFilterApplication = async (
    forcedNewSelection?: IdLabelObject[]
  ) => {
    const selection = forcedNewSelection ?? localSelection;
    setValues
      ? setValues(selection)
      : asyncSetValues
        ? await asyncSetValues(selection)
        : console.error(
            `no setValues or asyncSetValues bound/set in props, but here's the list or values on change: ${JSON.stringify(
              selection
            )}`
          );
  };

  /**
   * Filter options with search
   *
   * @local
   * @param option
   * @returns
   */
  const _filterWithSearch = (option: IdLabelObject) =>
    !search.length ||
    (search.length &&
      option.label.toLowerCase().includes(search.toLocaleLowerCase()));

  /**
   * Take care of selected and inputValue props
   *
   */
  // useEffect(() => {
  //   console.log("selected", selected)
  //   if (isEmpty(selected)) {
  //     setLocalInputValue('');
  //     setLocalSelection([]);
  //   } else {
  //     setLocalSelection(selected!);
  //     setLocalInputValue(inputValue);
  //   }
  // }, [selected, inputValue]);

  useEffect(() => {
    if (
      !focused &&
      (globalZIndex.zIndexValues.select ?? '').includes('opacity-0')
    ) {
      updateGlobalZIndex.onClose();
    }
  }, [focused]);

  return (
    <div className={`relative ${widthTailwind ?? 'w-56'}`}>
      <Combobox
        multiple
        value={[...localSelection]}
        onChange={handleChange}
        disabled={disabled ?? false}
      >
        {title ? (
          <p className='font-semibold text-[10px] pl-3 mb-1'>{title}</p>
        ) : null}
        <div className='relative w-full cursor-default overflow-hidden rounded-lg bg-white/70 text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm '>
          <Combobox.Input
            className={`w-full ${
              inputColor ?? 'bg-gray-500'
            } rounded-md border-none py-2 pl-3 pr-10 text-[11px] leading-5 focus:ring-0 font-medium ${
              selected?.length && !disabled
                ? 'text-oca-orange-100'
                : 'text-gray-400'
            } `}
            placeholder={focused ? inputValue : (placeholder ?? '')}
            // disabled
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value);
            }}
            onFocus={() => {
              setFocused(true);
              updateGlobalZIndex.onOpen();
            }}
            aria-multiselectable='true'
            autoComplete='off'
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => {
            setFocused(false);
            setSearch('');
            updateGlobalZIndex.onClose();
            if (localSelection.length !== selected?.length)
              setLocalSelection(selected ?? []); // reset selection when user clicks outside
          }}
        >
          <Combobox.Options className='absolute whitespace-pre-wrap overflow-hidden z-50 my-1 max-h-60 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {options?.length === 0 ? (
              <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                Liste non encore chargée ou vide.
              </div>
            ) : (
              <div className='relative max-h-[180px] overflow-auto mb-2 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-thumb-rounded-full py-2'>
                {options &&
                  sortBy(
                    [
                      extraOption ?? {
                        id: 'selectall',
                        label: '*Sélectionner/Désélectionner tout',
                      },
                      ...options,
                    ],
                    'label'
                  )
                    .filter(_filterWithSearch)
                    .map((option: IdLabelObject, index) => {
                      return (
                        <Combobox.Option
                          key={index}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                              active
                                ? 'bg-indigo-100 text-indigo-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={option}
                        >
                          {({ selected, active }) => {
                            return (
                              <>
                                <span
                                  className={`block truncate whitespace-pre-wrap text-sm ${
                                    option.id === 'selectall' || selected
                                      ? 'font-medium'
                                      : 'font-normal'
                                  } ${option.id === 'selectall' ? 'text-gray-500' : 'text-black'}`}
                                >
                                  {option.label}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-white' : 'text-emerald-500'
                                    } `}
                                  >
                                    <CheckIcon
                                      className='h-5 w-5'
                                      aria-hidden='true'
                                    />
                                  </span>
                                ) : null}
                              </>
                            );
                          }}
                        </Combobox.Option>
                      );
                    })}
              </div>
            )}
            <div className='flex justify-center items-end mt-2'>
              <Combobox.Button
                className='relative w-[80%] md:w-[60%] xl:w-[45%] bg-[#06396E] hover:bg-blue-gray-600 p-2 px-3 rounded-md shadow-md z-30 my-2'
                onClick={() => {
                  void handleFilterApplication(localSelection);
                }}
              >
                <p className='font-medium text-xs text-white'>Appliquer</p>
              </Combobox.Button>
            </div>
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}
