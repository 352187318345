import Image from 'next/image';
import React from 'react';

type Item = {
  value: string;
  title: string | string[];
  icon: string;
};

type Props = {
  values: Item[]; // Array of KPI with value, title and icon
  bgColor?: string; // Icon background color
  fontColor?: string; // Text color
  titleColor?: string; // Title color
  noBorder?: boolean; // Remove border
};

export default function IconCard({ values, bgColor, fontColor, titleColor, noBorder }: Props) {
  return (
    <div
      className={`flex items-center text-center rounded-lg border-[${
        noBorder ? '0' : '1'
      }px] border-gray-200 bg-white py-2 min-w-fit`}
    >
      <div className="flex px-4">
        {values.map((item) => (
          <React.Fragment key={item.title.toString()}>
            <span
              className={`inline-flex w-10 h-10 overflow-hidden relative justify-center items-center self-center rounded-full ${
                bgColor ?? 'bg-red-50'
              }`}
            >
              <Image
                className="object-contain object-center "
                src={item.icon}
                alt={item.icon}
                width={20}
                height={20}
              />
            </span>
            <div className="px-4">
              <p className={`text-xs text-left ${titleColor ?? 'text-[#5B6E9F] pt-1.5'}`}>
                {item.title.toString()}
              </p>
              <p
                className={`text-2xl font-semibold text-start
                  ${fontColor ? fontColor : 'text-[#346686]'}
                  `}
              >
                {item.value.includes('NaN') ? '/' : item.value}
              </p>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
