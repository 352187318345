function reduceNumber(num: number, decimals = 0, suffix?: string): string {
  if (num < 0) return `-${reduceNumber(-num, decimals, suffix)}`; // handle negative numbers
  const units = ['', '', 'M', 'Mds'];
  const unit = Math.floor((num.toFixed(0).length - 1) / 3) * 3;
  const reduced = Number(
    (num / 10 ** unit).toFixed(
      decimals > 0 ? decimals : (num / 10 ** unit).toFixed(0).length === 1 ? 2 : 0
    )
  );
  const result =
    Math.floor(unit / 3) <= 1
      ? `${num
          .toFixed(decimals)
          .replace(/\.[0]+$/, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}` // add space every 3 digits for thousands
      : `${reduced}${units[Math.floor(unit / 3)]}`; // add unit
  return suffix ? `${result}${suffix}` : result;
}

export function numberToString(
  num: number | string | null | undefined,
  reduce = false, // reduce number to k, m, b, t unit
  decimals = 0, // number of decimals
  suffix?: string
): string {
  if (num === null || num === undefined || isNaN(num as number)) return '-';
  if (reduce) return reduceNumber(num as number, decimals, suffix);
  return typeof num === 'number'
    ? `${num
        .toFixed(decimals)
        .replace(/\.[0]+$/, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}` // add space every 3 digits
    : num;
}
