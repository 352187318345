import type { IdLabelObject } from '@/types';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import type { ReactNode } from 'react';

type FiltersProps = {
  filters?: Record<string, IdLabelObject[]>;
  handleRemoveFilter?: (filter: IdLabelObject, filterid?: string) => void;
  handleResetFilters?: () => void;
};

export default function ActiveFiltersSection({
  filters,
  handleRemoveFilter,
  handleResetFilters,
}: FiltersProps) {
  const whenNotDefined = () => void 0;

  const _displayActiveFilters = (filters: Record<string, IdLabelObject[]>): ReactNode => {
    return Object.entries(filters).map(([_fkey, _fvalue]) =>
      _fvalue.map((filter: IdLabelObject, index: number) => (
        <div
          key={index}
          className="flex flex-row shadow-sm relative bg-blue-gray-400 rounded-md px-3 py-1 text-xs text-slate-700 m-0.5 max-w-2xl"
        >
          <p className="self-center font-semibold text-[9px] text-white">{filter.label}</p>

          <span className="text-white font-semibold pl-1 cursor-pointer self-center">
            <XMarkIcon
              className="h-3 w-3"
              aria-hidden="true"
              onClick={() => {
                (handleRemoveFilter ?? whenNotDefined)(filter, _fkey);
              }}
            />
          </span>
        </div>
      ))
    );
  };

  return (
    <div>
      <div className="flex flex-col justify-between pb-2">
        <div className="mx-1 mt-4 md:mt-1 mb-1">
          <h1 className="font-semibold text-[#06396E] text-sm 2xl:text-base">Mes filtres actifs</h1>
        </div>
      </div>
      {
        <div className="max-w-12xl mx-auto px-3 flex flex-row bg-gray-50 py-2 rounded-md">
          <div className="lg:inline self-center hover:item-orange-600 lg:mr-0 cursor-pointer">
            <TrashIcon
              onClick={handleResetFilters ?? whenNotDefined}
              className="h-5 w-5 mb-1 hover:text-gray-500 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <div className="flex flex-wrap overflow-x-auto ml-2 max-h-24 scrollbar-thin scrollbar-thumb-gray-900 scrollbar-thumb-rounded-full">
            {_displayActiveFilters(filters ?? {})}
          </div>
        </div>
      }
    </div>
  );
}
