// basic container for KPI
import { XMarkIcon } from '@heroicons/react/24/solid';
import type { FilterAttributes } from 'database/queryHelper';
import { endsWith, get, isEmpty, set } from 'lodash';
import type { RefObject } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Modal } from 'ui/Modal';
import Table from 'ui/Table';
import { generateCSV } from 'ui/export/excel';
import { generatePNG } from 'ui/export/png';
// Types
import type { DataSource } from '@/types';
// YAML file
import datasourcesyaml from '@/config/datasources.yaml';
import type { ReturnedRow } from 'database/bigQueryHelper';
import Image from 'next/image';

type Props = {
  title: string | string[];
  bqTable?: string;
  exportData?:
  | {
    csv: boolean;
    png: boolean;
    table?: boolean;
    bqQuery?: string;
    debug?: () => void;
    forcedPngParentRef?: RefObject<HTMLDivElement>;
  }
  | undefined;
  children: React.ReactNode;
  navigation?: React.ReactNode;
  height?: string;
  table?: {
    data: ReturnedRow[];
    columns: Record<string, string>;
    filterToExport?: FilterAttributes[];
  };
  px?: number;
  subSource?: boolean;
  timeToProcess?: number;
  alertSubText?: string;
};

export default function TitleSection({
  title,
  bqTable,
  exportData,
  children,
  height,
  table,
  px,
  navigation,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const refChild = useRef<HTMLDivElement>(null);
  const [showModalSource, setShowModalSource] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showExportIcon, setShowExportIcon] = useState(true);
  const [ds, setDs] = useState<DataSource | undefined>(undefined);

  useEffect(() => {
    if (bqTable) {
      const ds = get(datasourcesyaml as Record<string, DataSource>, bqTable);
      setDs(ds);
    }
  }, [bqTable]);

  const exportPNG = useCallback(async () => {
    setShowExportIcon(false);
    const link: HTMLAnchorElement = document.createElement('a');
    const current = ref; //document.getElementById('__next');
    // if (!current) return;
    await generatePNG(
      current,
      link,
      'OPCO2i_OBS_' + (typeof title === 'string' ? title : 'Indisponible'),
      refChild,
      height,
      'w-full h-[820px] overflow-y-auto items-center text-center rounded-lg bg-white min-w-full min-h-full',
      ref
    );
    setShowExportIcon(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, exportData?.forcedPngParentRef]);

  // /**
  //  * Checks if the sessions roles includes the role specified in the params
  //  *
  //  * @local
  //  * @state
  //  * @param role
  //  */
  // const requiredRoles = (roles: string[]) => {
  //   const allowed = false;

  //   if (isEmpty(roles)) return true;

  //   // // if no session object is found, the user will not be allowed to go further
  //   // if (!session) return false;

  //   // for (const role of roles) {
  //   //   if (session.user.authorization.roles.includes(role)) {
  //   //     allowed = true;
  //   //     break;
  //   //   }
  //   // }

  //   return allowed;
  // };

  const handleExportReportExcel = () => {
    const xlsx_pages = {}; // 1 page = 1 kpi // object of arrays of arrays => 1st line of the main array = labels; other lines = data; KEY = page name; DATA: xlsx table
    // remove characters between () and [] in title
    const tmp = typeof title === 'string' ? title : title[0];
    const newTtitle = tmp?.replace(/\([^)]*\)/g, '').replace(/\[[^\]]*\]/g, '').trim();
    const csv_filename = 'OPCO2i_OBS_' + (newTtitle ?? 'Indisponible');
    const keyfigures_page = {};

    const sortedKeysLabel: string[] = table?.columns ? Object.keys(table.columns) : [];
    const sortedObjLabel: Record<string, string> = {};
    sortedKeysLabel.forEach((key) => {
      sortedObjLabel[key] = table?.columns[key] ?? '';
    });
    const kpi_labels = Object.values(sortedObjLabel);
    const kpi_data = table?.data
      .filter((obj) => typeof obj.nb === 'number')
      .map((obj) => {
        // const sortedKeysValue = Object.keys(obj).sort();
        const sortedObjValue: Record<string, string> = {};
        sortedKeysLabel.forEach((key) => {
          sortedObjValue[key] = obj[key]?.toString() ?? '';
        });
        return Object.values(sortedObjValue);
      }) ?? [[]];

    const data = [];
    data.push(kpi_labels);
    data.push(...kpi_data);

    set(xlsx_pages, title, data);
    generateCSV(xlsx_pages, csv_filename, keyfigures_page);
  };

  return (
    <div
      className={`relative w-full h-[820px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scroll-m-6 scrollbar-track-gray-300/50 scrollbar-w-2 scrollbar-h-3 items-center text-center rounded-lg bg-white min-w-full min-h-full ${px ? `px-${px.toString()}` : ''
        }`}
      ref={ref}
    >
      <div className="flex space-x-2 justify-between">
        <p className="leading-6 text-left font-semibold text-[#06396E] text-sm xl:text-base pt-4 px-7">
          {typeof title === 'string' && title}
        </p>
        <div>
          {showExportIcon && (
            <div className="flex mr-7 mt-3 gap-1">
              {exportData?.png && (
                <button
                  onClick={() => { void exportPNG() }}
                  className="group w-full p-1 border-[2px] border-gray-200 rounded-md hover:opacity-50 z-0"
                >
                  <Image
                    alt="Export PNG"
                    width="20"
                    height="20"
                    className="opacity-80"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVR4nO2Zv0scQRTHPxESDIqkEA0Y/IFdBP8HLW0Eu5DGxi5gFa474j8gaCE2+hfYWemJjUKaNJIiISqCJP+ABlG8ZGThLQzDuu7em9k5ZL/w4G5m3nvzueHtzM1CrVq1iug1sAKcAW3AlLQ7YA3oIdLkjzuYdJZtxYBY8TT5aBBnVvLPwMuS/tEh2lbiVx34R18JY5nWfysGhPEI8ALYcNq2Q0P4BCAGhPEMUDmECQBQKYQJBJCoEggtwFOP4Z7QTyctgL0RNmJAGCXAF8WxY70bADSHwbtuAEjUCzSB0w6O42p5DRYjp6kBntEKjAEHwF+gBYxWkNNrsAMn3n4FOXODvQd2gN9iO9L2mK6deFcxAWYzJmSkLenLUqtbVmAOuMl5dt/ImKwa2BfIvVg1sCA7ZNp2CUyLXTq76IKnnCrZwT4A99b3C2DS+YVPrf5k111U5lTLDvbP+vwDGMkYPyJ9ts8SMCVF/kcsr+CDAaR2Agzl+AzJmHT8/0dq5hqYqRrgGzBYwO8N8LXAYe0WmK8K4AgYKOG76PgXLXgTCqCvhN9Hp+DPgXGrf0La0v578QkKUFRLTsH/BN5ljHsLfHdq5VNsgGWZiF3wwznjhzMKPhpAI0DBq1U0WENZ8P3AYQiAp+51ksupVSdpq2TBp+pzDnxJbrXy7nWSe5tNZ/K78ide8y7OiP3yAaC519Fas9te8pWxI8VKer3XKWNtydH0OflatZ6zHgA8gDk0IZOu7AAAAABJRU5ErkJggg=="
                  />

                  <div className="absolute z-10 p-1 mt-3 bg-black rounded-md shadow-md min-w-fit opacity-0 group-hover:opacity-100 duration-1000">
                    <span className="text-white text-[9px]">PNG</span>
                  </div>
                </button>
              )}

              {exportData?.csv && (
                <button
                  onClick={() => { setShowModalExport(true) }}
                  className="group w-full p-1 border-[2px] border-gray-200 rounded-md hover:opacity-50 z-0"
                >
                  <Image
                    className="opacity-80"
                    alt="Export Excel"
                    width="20"
                    height="20"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABNElEQVR4nO3WvUoDQRQF4E/wBxRt7CRgoZXY+VMHtLWzFHwAEbvUllY2voGNYCVaWkRQFNHCUjsrEXwBUTQSmGINazKRzEYkBy57z+XszNm7s+ylhx7awwDmsIV9PKIWIgkmsIIdXOA1s2FjdAwVHOE5Z5M33GAPa5hOYaCWiSecYBvLGG6hj4loA6VfGO6ogVjE6pMbqGXua1Zry8A45jEV+GI4hNcYLMLAbsgPAj8OfP0Hfey60cJJvOMFM/jAA/pz9Ek6UMdh4Gfhuuo7khtYyNRu0ddCH7tutLCcqd03tL+QDlTxidOcA5jcQDnkl5lXUf8DDjUxHPtgUcJqyDcCPw98s6gOjGEWo4Ev4Qp3GCnCQAySfwVdM1CKEee0u1X8/YGk0u2RrOtD6Z8ay3v4//gCSSEUh1cILSoAAAAASUVORK5CYII="
                  />
                  <div className="absolute z-10 p-1 mt-3 bg-black rounded-md shadow-md min-w-fit opacity-0 group-hover:opacity-100 duration-1000">
                    <span className="text-white text-[9px]">Excel</span>
                  </div>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {navigation && <>{navigation}</>}
      <div ref={refChild} className={`relative p-2 ${height ? height : 'h-[15rem]'} -mt-2`}>
        {children}
      </div>
      {showModalSource && !isEmpty(ds) && (
        <Modal
          show={showModalSource}
          onClose={() => {
            setShowModalSource(false);
          }}
        >
          <>
            <h3 className="text-lg font-medium leading-6 text-gray-900 border-b-2 border-gray-200 pb-4">
              <span>{`Informations concernant les données : ${typeof title === 'string' ? title : 'Indisponible'
                }`}</span>
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                <span className="text-sm font-bold">Source : </span>
                {ds.source}
                {endsWith(ds.source, '.') ? '' : '.'}
              </p>
              <p className="text-sm text-gray-500">
                <span className="text-sm font-bold">Date de la dernière donnée disponible : </span>
                {ds.update.last}
                {endsWith(ds.update.last, '.') ? '' : '.'}
              </p>
              <p className="text-sm text-gray-500">
                <span className="text-sm font-bold">Politique de mise à jour : </span>
                {ds.update.frequency}
                {endsWith(ds.update.frequency, '.') ? '' : '.'}
              </p>
              {/* <p className="text-sm text-gray-500">
                                <span className="text-sm font-bold">Dernière mise à jour : </span>
                                {ds.update.last_update}
                                {endsWith(ds.update.frequency, '.') ? '' : '.'}
                            </p> */}
            </div>
          </>
        </Modal>
      )}
      {showModalExport && (
        <Modal
          show={showModalExport}
          onClose={() => {
            setShowModalExport(false);
          }}
        >
          <>
            <div className="flex items-start justify-between rounded-t text-lg font-medium leading-6 text-gray-900 border-b-2 border-gray-200 pb-4">
              <span>{typeof title === 'string' ? title.replace(/\([^)]*\)/g, '').replace(/\[[^\]]*\]/g, '').trim() : 'Indisponible'}</span>
              <div className="lg:flex lg:items-center lg:justify-between">
                <div className="mt-5 flex lg:mt-0 lg:ml-4">
                  <span className="hidden sm:block">
                    <button
                      onClick={handleExportReportExcel}
                      type="button"
                      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Exporter
                    </button>
                  </span>

                  <span className="ml-3 hidden sm:block">
                    <button
                      onClick={() => {
                        setShowModalExport(false);
                      }}
                      type="button"
                      className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </span>
                </div>
              </div>
            </div>
            {table !== undefined ? <Table data={table.data} columns={table.columns} /> : null}
          </>
        </Modal>
      )}
      {/* logo komete */}
      {showExportIcon ? <div className='flex flex-row self-center justify-end w-full items-center pr-8 pb-8'>
        Solution
        <Image
          className="h-6 w-16 object-contain sm:h-8 sm:w-24"
          src={'/logo-komete.svg'}
          alt="Logo Komète"
          width={284}
          height={100}
        ></Image>
      </div> : null}
    </div>
  );
}
