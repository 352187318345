// Helpers
import type { IdLabelObject } from '@/types';
import { useState } from 'react';

type IdLabelObjectProps = IdLabelObject & {
  textColor: string;
  bgColor: string;
};

type NavigationProps = {
  id: string;
  _navigate: (id: string) => void;
};

const _navigation: IdLabelObjectProps[] = [
  {
    id: '1',
    label: 'Entreprises',
    bgColor: 'bg-[#eb640a]',
    textColor: 'text-[#eb640a]',
  },
  {
    id: '2',
    label: 'Établissements',
    bgColor: 'bg-[#eb640a]',
    textColor: 'text-[#eb640a]',
  },
  {
    id: '4',
    label: 'Salariés',
    bgColor: 'bg-[#D2145A]',
    textColor: 'text-[#D2145A]',
  },
  {
    id: '3',
    label: "Contrats d'alternance",
    bgColor: 'bg-[#6D2861]',
    textColor: 'text-[#6D2861]',
  },
  {
    id: '5',
    label: 'Répartitions régionales',
    bgColor: 'bg-[#06396E]',
    textColor: 'text-[#06396E]',
  },
];

export default function Navigation({ id, _navigate }: NavigationProps) {
  const selected = _navigation.find((_nav: IdLabelObjectProps) => _nav.id === id);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      {/* Render for big screen (> xl) */}
      <div className="hidden xl:block pt-2">
        <div className="flex flex-row items-center px-10 justify-end">
          {_navigation.map((_nav: IdLabelObjectProps, idx: number) => (
            <button
              key={_nav.id}
              className={`cursor-pointer whitespace-nowrap relative inline-flex gap-8 justify-between items-center ${
                idx < _navigation.length - 1 ? 'mr-[15px]' : ''
              } px-8 py-[15px] rounded-t-3xl ${
                id === _nav.id
                  ? `bg-white ${_nav.textColor || 'text-[#eb640a]'} font-bold`
                  : ` ${_nav.bgColor || 'bg-[#eb640a]'} text-white font-normal`
              }`}
              onClick={() => {
                _navigate(_nav.id);
              }}
            >
              {_nav.label}
            </button>
          ))}
        </div>
      </div>
      {/* Render for samll screen */}
      <div className="xl:hidden pt-2">
        <div className="flex flex-wrap items-center sm:px-10 px-3 justify-end">
          <button
            className={`cursor-pointer whitespace-nowrap relative inline-flex w-full items-center px-8 
                        py-[15px] rounded-t-3xl ${selected?.bgColor} text-white font-bold`}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {selected?.label}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className={`w-5 h-5 ml-2 ${open ? 'rotate-180 duration-150' : 'duration-150'}`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </button>
          <div className={`${open ? 'block' : 'hidden'} flex flex-wrap w-full`}>
            {_navigation
              .filter((_nav: IdLabelObjectProps) => _nav.id !== id)
              .map((_nav: IdLabelObjectProps) => (
                <button
                  key={_nav.id}
                  className={`cursor-pointer whitespace-nowrap relative inline-flex w-full items-center px-8 
                                    py-[15px] ${_nav.bgColor} text-white font-semibold`}
                  onClick={() => {
                    _navigate(_nav.id);
                  }}
                >
                  {_nav.label}
                </button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
