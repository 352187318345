import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type SortingState,
} from '@tanstack/react-table';
import { useState } from 'react';

export type TableProps = {
  data: Record<string, string | number | boolean>[];
  columns: Record<string, string>;
  pageSize?: number;
  styles?: Record<string, number>;
  columnAuto?: boolean;
  suffix?: string;
};

function dataWithRoundedElement(
  rows: Record<string, string | number | boolean | null>[],
  suffix?: string
) {
  const roundedData = rows.map((row) => {
    const rowMap = new Map(Object.entries(row));
    Object.entries(row).forEach(([key, value]) => {
      if (value === null) {
        rowMap.set(key, '');
      } else if (typeof value !== 'string') {
        if (key.includes('evolution_rate')) {
          rowMap.set(key, value ? value.toLocaleString(undefined, { maximumFractionDigits: 0 }) + '%' : 0 + '%');
        } else {
          rowMap.set(key, value.toLocaleString(undefined, { maximumFractionDigits: 0 }));
        }
      } else {
        if (key === 'nb') {
          rowMap.set(key, suffix ? value + ' ' + suffix : value.toString());
        }
        if (key.includes('evolution_rate')) {
          rowMap.set(key, value ? value + '%' : 0 + '%');
        }
      }
    });
    // for (const [key, value] of rowMap) {
    //   if (value === null) {
    //     rowMap.set(key, '')
    //   } else if (typeof value !== 'string') {
    //     rowMap.set(key, value.toLocaleString(undefined, { maximumFractionDigits: 0 }));
    //   } else {
    //     if (key === 'nb') {
    //       rowMap.set(key, suffix ? value + ' ' + suffix : value.toString());
    //     }
    //     if (key === 'evolution_rate') {
    //       rowMap.set(key, value ? value + '%' : 0 + '%');
    //     }
    //   }
    // }
    return Object.fromEntries(rowMap) as Record<string, string>;
  });
  return roundedData;
}

function createTableColumns(columns: Record<string, string>) {
  const columnHelper = createColumnHelper<typeof columns>();
  const cols = [];
  for (const column in columns) {
    cols.push(
      columnHelper.accessor(column, {
        id: column,
        header: () => columns[column],
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
      })
    );
  }
  return cols;
}

export default function Table({ data, columns, pageSize, styles, columnAuto, suffix }: TableProps) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [tableData] = useState(dataWithRoundedElement(data, suffix));
  const [tableColumns] = useState(createTableColumns(columns));

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    initialState: {
      pagination: {
        pageSize: pageSize ?? 5,
        pageIndex: 0,
      },
    },
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: process.env.NODE_ENV === 'development',
  });

  const startRow = table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1;
  const endRow = startRow + table.getRowModel().rows.length - 1;
  return (
    <div className="h-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded">
      <table
        className={
          `${columnAuto ? 'table-auto' : 'table-fixed'}` +
          ' divide-y divide-gray-200 text-left w-full'
        }
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`px-6 py-3 text-xs font-semibold tracking-wider text-black`}
                  style={{
                    minWidth: styles
                      ? header.index === 0
                        ? styles.firstColumnWidth
                        : styles.othersColumnWidth
                      : 'auto',
                    maxWidth: styles
                      ? header.index === 0
                        ? styles.firstColumnWidth
                        : styles.othersColumnWidth
                      : 'auto',
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <span className="px-2">↑</span>,
                        desc: <span className="px-2">↓</span>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-xs">
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const _cellv = cell.getContext().getValue();
                  const _noturlcondition = !cell.id.endsWith('url');
                  const renderAndCheckIfURL = (
                    children: JSX.Element,
                    url: string,
                    preventDefault?: boolean // if true, prevent default behavior of <a> tag, else, open link in new tab
                  ) => {
                    return preventDefault ? (
                      <>{children}</>
                    ) : (
                      <a
                        href={encodeURI(`https://${url}`)}
                        target="_blank"
                        className="cursor-pointer text-blue-700 hover:underline"
                      >
                        <p className="truncate">{children}</p>
                      </a>
                    );
                  };

                  return (
                    <td key={cell.id} className="px-6 py-4">
                      {renderAndCheckIfURL(
                        <>{flexRender(cell.column.columnDef.cell, cell.getContext())}</>,
                        _cellv as string,
                        _noturlcondition
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="flex flex-1 justify-between sm:hidden">
          <button
            onClick={() => {
              table.previousPage();
            }}
            disabled={!table.getCanPreviousPage()}
            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-50"
          >
            Précédent
          </button>
          <button
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
            disabled={!table.getCanNextPage()}
            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-black hover:bg-gray-50"
          >
            Suivant
          </button>
        </div>
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between text-xs">
          <div>
            <span>Lignes par page </span>
            <select
              className="border-gray-300 rounded-md shadow-sm h-10 text-xs focus:ring-gray-500 focus:border-gray-500"
              value={table.getState().pagination.pageSize}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[5, 10, 15, 20].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div>
            <p className="text-xs text-black">
              <span className="font-medium">{startRow}</span> -{' '}
              <span className="font-medium">{endRow}</span> sur{' '}
              <span className="font-medium">{table.getPreFilteredRowModel().rows.length}</span>
            </p>
          </div>
          <div>
            <button
              className={"border rounded p-1" + (table.getCanPreviousPage() ? " cursor-pointer" : " cursor-not-allowed bg-gray-100")}
              onClick={() => {
                table.setPageIndex(0);
              }}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className={"border rounded p-1" + (table.getCanPreviousPage() ? " cursor-pointer" : " cursor-not-allowed bg-gray-100")}
              onClick={() => {
                table.previousPage();
              }}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className={"border rounded p-1" + (table.getCanNextPage() ? " cursor-pointer" : " cursor-not-allowed bg-gray-100")}
              onClick={() => {
                table.nextPage();
              }}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className={"border rounded p-1" + (table.getCanNextPage() ? " cursor-pointer" : " cursor-not-allowed bg-gray-100")}
              onClick={() => {
                table.setPageIndex(table.getPageCount() - 1);
              }}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
