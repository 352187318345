export type Filter = Record<
  string,
  string | number | boolean | string[] | number[] | boolean[] | null
>;

export type FilterAttributes = {
  id: string | number;
  label: string | number;
  field?: string;
  ref: string | number;
  value?: string | number | boolean | string[] | number[] | null;
  name?: string | number;
  is_applied?: boolean;
  simpleSelect?: boolean;
  uniq?: boolean;
};

export type BuildedQuery = {
  dimensions: string[];
  aggregationAnnee?: number;
  aggregationMeasure?: string;
  aggregationFunction?:
    | 'sum'
    | 'min'
    | 'max'
    | 'avg'
    | 'count'
    | 'count_distinct'
    | 'rand'
    | 'like';
  measure?: string;
  measureAlias?: string;
  additionalMeasures?: string[];
  table: string;
  flatten?: string;
  filter?: Filter;
  orderBy?: string[];
  orderDirection?: 'ASC' | 'DESC';
  limit?: number;
  imputeWith?: string;
};

export type ForcedQuery = {
  stringified: string;
  filter?: Filter; // This is used to popuplate a forced query with filters selected by the user
};

export type QueryProps = BuildedQuery | ForcedQuery;

export const dict = {
  count_distinct: 'COUNT DISTINCT',
  count: 'COUNT',
  sum: 'SUM',
  min: 'MIN',
  max: 'MAX',
  avg: 'AVG',
  rand: 'RAND',
  like: 'LIKE',
};

export function whereClause(filter: Filter) {
  const returnedString = Object.keys(filter)
    .map((field) => {
      const filterValue = filter[field];

      if (filterValue === undefined || filterValue === null) {
        return '';
      }
      if (typeof filterValue === 'string') {
        if (filterValue.toUpperCase().includes('LIKE')) {
          return `UPPER(${field}) LIKE '%${filterValue
            .normalize('NFD')
            .replaceAll(/[\u0300-\u036f]/g, '')
            .replaceAll(/[^a-zA-Z\d\s]/g, ' ')
            .toUpperCase()
            .replace('LIKE', '')
            .trim()}%'`;
        }
        if (filterValue.toUpperCase().includes('SEARCH')) {
          return `SEARCH(${field}, '${filterValue
            .normalize('NFD')
            .replaceAll(/[\u0300-\u036f]/g, '')
            .replaceAll(/[^a-zA-Z\d\s]/g, ' ')
            .toUpperCase()
            .replaceAll('SEARCH', '')
            .trim()}')`;
        }
        if (filterValue.toUpperCase().includes('NOT IN ')) {
          return `${field} NOT IN ${filterValue.replace('NOT IN', '')}`;
        }
        if (filterValue.toUpperCase().includes('IN ')) {
          return `${field} IN ${filterValue.replace('IN', '')}`;
        }
        if (filterValue.toUpperCase().includes('IS NOT NULL')) {
          return `${field} IS NOT NULL`;
        }
        if (filterValue.toUpperCase().includes('IS NULL')) {
          return `${field} IS NULL`;
        }
        return `${field} = '${filterValue}'`;
      }
      if (typeof filterValue === 'number') {
        return `${field} = ${filterValue}`;
      }
      if (typeof filterValue === 'boolean') {
        return `${field} = ${filterValue}`;
      }
      return `${field} IN (${filterValue
        .map((val) => (typeof val === 'number' ? val : `"${val}"`))
        .join(',')})`;
    })
    .reduce((previousValue, currentValue, index, array) => {
      if (
        index === 1 &&
        previousValue.includes('SEARCH') &&
        currentValue.includes('SEARCH')
      )
        return `(${previousValue} OR ${currentValue}`;
      if (
        !array[index - 1]!.includes('SEARCH') &&
        currentValue.includes('SEARCH') &&
        index !== array.length - 1
      )
        return `${previousValue} AND (${currentValue}`;
      if (
        array[index - 1]!.includes('SEARCH') &&
        !currentValue.includes('SEARCH') &&
        index !== 1
      )
        return `${previousValue}) AND ${currentValue}`;
      if (
        array[index - 1]!.includes('SEARCH') &&
        currentValue.includes('SEARCH')
      )
        return `${previousValue} OR ${currentValue}`;
      return `${previousValue} AND ${currentValue}`;
    });
  return returnedString;
}

export function aggregateQuery(props: BuildedQuery) {
  if (
    (props.measure === null || props.measure === undefined) &&
    !!props.aggregationFunction
  )
    throw new Error(
      'You cannot specify an aggregation function without providing a measure'
    );

  const dimensionsString = props.dimensions.reduce(
    (previousValue, currentValue) => `${previousValue}, ${currentValue}`
  );
  const orderByString =
    !!props.orderBy && props.orderBy.length > 0
      ? props.orderBy.reduce(
          (previousValue, currentValue) => `${previousValue}, ${currentValue}`
        )
      : '1';
  const safeMeasureAlias =
    props.measureAlias ?? `${props.aggregationFunction}_${props.measure}`;

  let select: string;
  // If no aggregation function is provided, we assume the user wants to pass a stringified script in the measure field
  if (!props.aggregationFunction) {
    select = `${props.measure} AS ${safeMeasureAlias}`;
  } else {
    if (props.aggregationFunction === 'count_distinct') {
      select = `count(distinct(${props.measure})) AS ${safeMeasureAlias}`;
    } else {
      select = `${props.aggregationFunction}(${props.measure}) AS ${safeMeasureAlias}`;
    }
  }

  if (props.additionalMeasures) {
    select += `, ${props.additionalMeasures.join(', ')}`;
  }

  let flatten = '';
  if (props.flatten) {
    flatten = `, ${props.flatten}`;
  }
  let aggregationAnnee = '';
  if (props.aggregationAnnee && props.filter?.annee) {
    const filterAnnee = props.filter.annee as number[] | string[];
    for (const annee of filterAnnee) {
      aggregationAnnee += `SUM(CASE WHEN annee = ${annee} THEN ${props.measure} END) AS nb_${annee},`;
    }
  }
  return (
    `SELECT ${dimensionsString}` +
    (!(props.measure === null || props.measure === undefined)
      ? `, ${select}`
      : '') +
    (props.aggregationAnnee ? `, ${aggregationAnnee}` : '') +
    `
FROM ${props.table} ${flatten} ${
      props.filter && Object.keys(props.filter).length > 0
        ? `\nWHERE ${whereClause(props.filter)}`
        : ``
    }
GROUP BY ${
      // Create an array with SELECT values to GROUP BY [1, 2, 3, ...]
      // To see more about the method (https://stackoverflow.com/questions/3746725/how-to-create-an-array-containing-1-n)
      Array.from({ length: props.dimensions.length || 0 }, (_, i) =>
        (i + 1).toString()
      ).reduce(
        (previousValue, currentValue) => `${previousValue}, ${currentValue}`
      )
    } 
ORDER BY ${orderByString} ${props.orderDirection ?? 'ASC'}
${props.limit ? `LIMIT ${props.limit}` : ''}`
  );
}

/**
 * This function is used to create a query that will be used to impute missing values
 * Attenzione: This function is not treating the case of additional measure
 * @param props
 * @param aggregateQueryFn
 * @returns
 */

// A refaire complètement => Incompréhensible !!!
// eslint-disable-next-line @typescript-eslint/ban-types
export function imputedQuery(props: BuildedQuery, aggregateQueryFn: Function) {
  // we delete the filter to get all the available values in the database for the imputation and we replace the measure by the imputeWith value
  const imputationQueryProps = {
    ...props,
    filter: {},
    measure: props.imputeWith,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const imputationQuery = aggregateQueryFn(imputationQueryProps);

  const _ma = props.measureAlias ?? `nb`;
  const _orderby =
    !!props.orderBy && props.orderBy.length > 0
      ? props.orderBy.reduce(
          (previousValue, currentValue) => `${previousValue}, ${currentValue}`
        )
      : '1';

  const _q = `WITH 
    imputation AS (${imputationQuery})\n, 
    base AS (${aggregateQueryFn(props)})
    SELECT 
      imputation.* EXCEPT (${_ma}), 
      COALESCE(base.${_ma}, imputation.${_ma}) AS ${_ma}
    FROM base
    RIGHT JOIN imputation USING (${props.dimensions
      .map((_dim) =>
        _dim.includes('AS') ? _dim.split('AS').pop() : _dim.split(' ').pop()
      )
      .join(', ')})
    ORDER BY ${_orderby} ${props.orderDirection ?? 'ASC'}
  `;

  return _q;
}

export function countLinesQuery(table: string, filter?: Filter) {
  return (
    `SELECT count(1) AS nblignes FROM ${table}` +
    (!!filter && Object.keys(filter).length > 0
      ? `\nWHERE ${whereClause(filter)}`
      : '')
  );
}

export function isForcedQuery(query: QueryProps): boolean {
  return 'stringified' in query;
}

export function populateForcedQueryWithFilters(query: ForcedQuery) {
  if (!query.filter) return query.stringified.replaceAll('${where_clause}', '');
  const filterSectionReplacement = whereClause(query.filter);

  return query.stringified.replaceAll(
    '${where_clause}',
    `WHERE ${filterSectionReplacement}`
  );
}
